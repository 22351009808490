/* eslint-disable camelcase */
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Loader from 'src/components/loader'
import ModelViewer from 'src/components/ModelViewer'

const ModelViewerModal = ({ show, model, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rotation, setRotation] = useState({})

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const canvasHeightForViewportSize = useMemo(() => {
    const breakpoints = { tablet: 1024, mobile: 768 };
    return viewportWidth >= breakpoints.tablet
      ? 600
      : viewportWidth >= breakpoints.mobile
        ? 500
        : 300;
  }, [viewportWidth]);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const modelViewerMode = {
    SOLID: 'solid',
    WIREFRAME: 'wireframe',
  };

  const loaderStyle = {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Modal size="lg" show={show} onHide={close}>
      <Modal.Header className='d-flex w-100 justify-content-between'>
        {model?.name}
        <FontAwesomeIcon role="button" className='action-panel-close-btn-icon cursor-pointer' icon={faXmark} onClick={close} />
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <div style={loaderStyle}>
            <Loader />
          </div>
        )}
        {model && (
          <ModelViewer
            enableRotate
            model={model.content}
            unit={model.user_unit}
            fileUnit={model.file_unit}
            mode={modelViewerMode.SOLID}
            canvasHeight={canvasHeightForViewportSize}
            rotation={rotation}
            onStart={() => setIsLoading(true)}
            onLoadComplete={() => setIsLoading(false)}
            onRotationChange={newRotation => {
              console.log({ newRotation })
              setRotation(newRotation)
            }}
           />
          )}
      </Modal.Body>
    </Modal>
  )
}

export default ModelViewerModal

ModelViewerModal.propTypes = {
    model: PropTypes.shape({
      name: PropTypes.string,
      file_unit: PropTypes.string,
      user_unit: PropTypes.string,
      content: PropTypes.string,
    }).isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
}