import { faExclamationTriangle, faQrcode, faUser } from '@fortawesome/pro-regular-svg-icons';
import {
  faBarcode,
  faClock,
  faCog,
  faObjectGroup, faRecycle,
  faSortAmountUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _last from 'lodash/last';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Feature from 'src/components/feature';
import IntlDate from 'src/components/intl-date';
import Loader from 'src/components/loader';
import { ToolingStockCardPreview } from 'src/components/ToolingStockCard';
import {
  ALLOWED_RUN_STATUSES_FOR_TOOLING_STOCK_SCAN,
  API_RESOURCES,
  BUREAU_BARCODE_FORMAT,
  LOCALSTORAGE_KEYS,
  RUN_ACTIONS,
  RUN_STATUS,
  RUN_STATUS_LABELS,
} from 'src/utils/constants';
import { FEATURES } from 'src/utils/features';
import { getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import useLocalstorage from 'src/utils/useLocalStorage';
import { isValidDate } from 'src/utils/validation';

import routes from '../../../../utils/routes';

const STATUS_LABELS_OVERRIDES = {
  ...RUN_STATUS_LABELS,
  [RUN_STATUS.inProgress]: 'In Progress',
};

const STATUS_BADGE_COLORS = {
  [RUN_STATUS.inProgress]: '#ffa500',
  [RUN_STATUS.complete]: '#1bc98e',
  [RUN_STATUS.error]: '#e64759',
  [RUN_STATUS.cancelled]: '#555555',
  [RUN_STATUS.queued]: '#1ca8dd',
  [RUN_STATUS.queuedReady]: '#1ca8dd',
};

const RunHeader = ({
                     attendTimeEntryUser,
                     run,
                     workstation,
                     scheduledRun,
                     runMachineTimeEntries,
                     latestAttendTimeTrackingEntry,
                     workstationNextRun,
                     materialInfo,
                     labelsByPiece,
                     isToolingStockTypeFeatureEnabled,
                     toolingStockData,
                   }) => {
  const [loading, setLoading] = useState(true);
  const estimatedStartDate =
    scheduledRun
    && scheduledRun.estimates
    && scheduledRun.estimates.start
    && new Date(scheduledRun.estimates.start);
  const estimatedEndDate =
    scheduledRun
    && scheduledRun.estimates
    && scheduledRun.estimates.end
    && new Date(scheduledRun.estimates.end);

  const showPauseReason = Boolean(
    latestAttendTimeTrackingEntry && latestAttendTimeTrackingEntry.end_time && latestAttendTimeTrackingEntry.notes &&
    run.status !== RUN_STATUS.complete,
  );

  const navigate = useNavigate();

  const isPaused = run.status === RUN_STATUS.paused;
  const isComplete = run.status === RUN_STATUS.complete;
  const latestMachineTimeEntry = _last(runMachineTimeEntries);
  const [isUserAttending, setIsUserAttending] = useState(latestAttendTimeTrackingEntry && isValidDate(latestAttendTimeTrackingEntry.start_time));
  const [isUserActive, setIsUserActive] = useState(isUserAttending && !isValidDate(latestAttendTimeTrackingEntry.end_time));
  const hasStarted =
    run.status === RUN_STATUS.inProgress
    // If run is not ended - we need to check for the latest time entry too
    && (!latestMachineTimeEntry || !latestMachineTimeEntry.end_time);
  const displayEstimates = !hasStarted && !isPaused && !isComplete && estimatedStartDate;

  const showNextWorkstationRun = run.status === RUN_STATUS.complete;

  const [scanMode] = useLocalstorage(LOCALSTORAGE_KEYS.SCAN_MODE, BUREAU_BARCODE_FORMAT.QR);
  const isBarcode = scanMode === BUREAU_BARCODE_FORMAT.BARCODE;

  const { toolingStock, toolingType, toolingStockLocation, toolingStockSubLocation } = toolingStockData ?? {};

  const isAppropriateStatusForToolingStock = ALLOWED_RUN_STATUSES_FOR_TOOLING_STOCK_SCAN.includes(run.status);


  useEffect(() => {
    if (latestAttendTimeTrackingEntry && isValidDate(latestAttendTimeTrackingEntry.start_time)) {
      setIsUserAttending(true);
      setIsUserActive(!isValidDate(latestAttendTimeTrackingEntry.end_time));
    }
  }, [latestAttendTimeTrackingEntry, attendTimeEntryUser]);

  const handleScanToolingStock = () => {
    navigate(getRouteURI(routes.scan,
      {},
      {
        runUri: run.uri,
        action: RUN_ACTIONS.SCAN_TOOLING_STOCK,
        entity: API_RESOURCES.TOOLING_STOCK,
      }));
  };

  const renderToolingStockPreview = () => {
    if (toolingStock) {
      return (
        <ToolingStockCardPreview
          location={toolingStockLocation}
          subLocation={toolingStockSubLocation}
          toolingType={toolingType}
          toolingStock={toolingStock}
        />
      );
    }
  };

  const renderToolingStockScan = () => {
    if (!isAppropriateStatusForToolingStock || toolingStock) return null;

    return (
      <div className="run-header-status mb-2 mt-2">
        <div className="d-flex">
          <button
            type="button" className="btn btn-secondary btn-sm alert-link text-white d-flex align-items-center p-2"
            onClick={handleScanToolingStock}
          >
            <FontAwesomeIcon icon={isBarcode ? faBarcode : faQrcode} className="mr-1 font-size-16" />
            Scan Tool
          </button>
        </div>
      </div>
    );
  };

  const renderMaterialInfo = () => {

    const { materialBatch, material } = materialInfo;

    if ((materialBatch && material) && (Object.keys(materialBatch).length && Object.keys(material).length)) {
      // We have information about Material Batches and can display it
      const { uuid: currentBatchId, usage_cycles: cycles, quantity, units } = materialBatch;
      const { name, color } = material;
      const batchID = getShortUuid(currentBatchId);
      /* to emulate loading effect as there is a chance we will not have values of
         materialInfo, and we will not show the loader at all */
      setTimeout(() => setLoading(false), 1000);

      return (
        loading ?
          <Loader showText={false} /> : (
            <div className="labelStyleContainer">
              <span className="labelsStyleTitle">
                Materials:
              </span>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Batch ID</Tooltip>}
              >
                <p className="labelStyle"><FontAwesomeIcon icon={faCog} className="mr-1" /> {batchID}</p>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Material Type&Color</Tooltip>}
              >
                <p className="labelStyle"><FontAwesomeIcon icon={faObjectGroup} className="mr-1" /> {name} <span
                  className="labelStyleColor" style={{ background: color }} />
                </p>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Amount Loaded</Tooltip>}
              >
                <p className="labelStyle"><FontAwesomeIcon icon={faSortAmountUp} className="mr-1" /> {quantity} {units}</p>
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">Batch Usage Cycles</Tooltip>}
              >
                <p className="labelStyle"><FontAwesomeIcon icon={faRecycle} className="mr-1" /> {cycles}</p>
              </OverlayTrigger>
            </div>
          )
      );
    }
  };
  const labelsByPieceCount = Object.keys(labelsByPiece).length;
  return (
    <>
      <div className="run-header-status mb-2">
        <div className="d-flex">
          {/* eslint-disable-next-line react/prop-types */}
          {run.pieces_locked && (
            <div className="text-left">
              <span className="badge badge-info">Run Locked</span>
            </div>
          )}
          <div className="mr-auto ml-auto">
            <h4>
              Status:&nbsp;
              {/* eslint-disable-next-line react/jsx-child-element-spacing */}
              <span
                className="badge badge-secondary"
                style={{ backgroundColor: STATUS_BADGE_COLORS[run.status] }}
              >
                {STATUS_LABELS_OVERRIDES[run.status]}
              </span>
            </h4>
            {!!labelsByPieceCount &&
              <div className="mt-1">
                <OverlayTrigger
                  placement="bottom"
                  overlay={(
                    <Tooltip>
                      {labelsByPieceCount} piece(s) in this run are export controlled. Please use necessary caution.
                    </Tooltip>)}
                >
                  <h4 className="d-inline">
                    <Badge bg="warning" className="p-2">
                      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
                      Warning
                    </Badge>
                  </h4>
                </OverlayTrigger>

              </div>}
          </div>
          <Feature featureName={FEATURES.USER_RUN_TIME_TRACKING}>
            {
              isUserAttending && attendTimeEntryUser ? (
                <div className="d-flex align-items-center">
                  <OverlayTrigger
                    placement="top"
                    overlay={(
                      <Tooltip id="tooltip">
                        {`Run 
                    ${isUserActive ? 'is' : 'was'}
                     Attended by ${attendTimeEntryUser.name} since ${new Date(latestAttendTimeTrackingEntry.start_time).toLocaleDateString()}`}
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon
                      icon={faUser} className={`mr-1 ${
                      isUserActive ?
                        'run-workstation-user-icon-active' :
                        'run-workstation-user-icon-away'}`} />
                  </OverlayTrigger>
                </div>
              ) : null
            }

          </Feature>
        </div>

        {/* Check about AM/PM hour12: true */}
        <h5 className="d-flex mb-3 justify-content-center">
          {displayEstimates && (
            <div className="d-flex">
              <div className="mr-1 d-flex align-items-center">
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id="workstation-is-overloaded">
                      Scheduled
                    </Tooltip>)}
                >
                  <FontAwesomeIcon icon={faClock} className="ml-1" />
                </OverlayTrigger>

                <IntlDate suppressHydrationWarning withTime disableSeconds className="badge" date={estimatedStartDate} />
              </div>
              {estimatedEndDate && (
                <div className="mr-1 d-flex align-items-center">-<IntlDate
                  suppressHydrationWarning withTime
                  disableSeconds date={estimatedEndDate}
                  className="badge" />
                </div>
              )}
            </div>
          )}
        </h5>
      </div>
      {workstation && (
        <div className="run-workstation-body">
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip id="workstation-title">
                {workstation.name}
              </Tooltip>)}
          >
            <h3 className="run-workstation-title">Workstation:
              {/* eslint-disable-next-line react/jsx-child-element-spacing */}
              <span className="badge badge-secondary ml-1 run-workstation-title-text">{workstation.name}</span>
            </h3>
          </OverlayTrigger>

          <p className="mb-0">In
            progress: {workstation.in_progress?.length || 0} of {workstation.in_progress_max || 0}
          </p>
          {materialInfo && renderMaterialInfo()}
          {isToolingStockTypeFeatureEnabled && renderToolingStockScan()}
          {isToolingStockTypeFeatureEnabled && renderToolingStockPreview()}
        </div>
      )}
      {showNextWorkstationRun && (
        <h2>
          Next at Workstation:
          {' '}
          {
            workstationNextRun
              ? (
                <Link to={getRouteURI(routes.run, { uuid: getUuid(workstationNextRun.uri) })}>
                  {workstationNextRun.name}
                </Link>
              )
              : 'None'
          }
        </h2>
      )}
      {showPauseReason && (
        <h4>Reason for Pause: {latestAttendTimeTrackingEntry.notes}</h4>
      )}
    </>
  );
};

RunHeader.propTypes = {
  attendTimeEntryUser: PropTypes.shape({
    name: PropTypes.string,
  }),
  run: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    pieces_locked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    scheduled: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }).isRequired,
    prints: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  runMachineTimeEntries: PropTypes.arrayOf(PropTypes.shape({
    // eslint-disable-next-line camelcase
    start_time: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    end_time: PropTypes.string,
  })),
  scheduledRun: PropTypes.shape({
    estimates: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string,
    }),
  }),
  workstation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line camelcase
    in_progress: PropTypes.arrayOf(PropTypes.string).isRequired,
    // eslint-disable-next-line camelcase
    in_progress_max: PropTypes.number.isRequired,
  }),
  latestAttendTimeTrackingEntry: PropTypes.shape({
    // eslint-disable-next-line camelcase
    start_time: PropTypes.string,
    // eslint-disable-next-line camelcase
    end_time: PropTypes.string,
    notes: PropTypes.string,
  }),
  workstationNextRun: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  materialInfo: PropTypes.shape({
    materialBatch: PropTypes.object,
    material: PropTypes.object,
  }),
  labelsByPiece: PropTypes.shape({}).isRequired,
  isToolingStockTypeFeatureEnabled: PropTypes.bool.isRequired,
  toolingStockData: PropTypes.shape({
    toolingStock: PropTypes.shape({}),
    toolingType: PropTypes.shape({}),
    toolingStockLocation: PropTypes.string,
    toolingStockSubLocation: PropTypes.string,
  }).isRequired,
};

RunHeader.defaultProps = {
  attendTimeEntryUser: null,
  run: {},
  latestAttendTimeTrackingEntry: null,
  runMachineTimeEntries: [],
  scheduledRun: null,
  workstation: null,
  workstationNextRun: null,
  materialInfo: null,
};

export default RunHeader;
