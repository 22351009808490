import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Feature from 'src/components/feature';
import Loader from 'src/components/loader';
import { accessProhibitedByGroupNames, isAccessAllowed } from 'src/utils/accessInfo';
import { api } from 'src/utils/api';
import { ACCESS_INFO_ACTION_TYPES } from 'src/utils/constants';
import { FEATURES } from 'src/utils/features';
import { getRouteURI, getUuid } from 'src/utils/url';

import routes from '../../../../utils/routes';
import { GROUP_STATES } from '../index';
import NotesModal from './_notes-modal';
import WorkstationModal from './_workstation-modal';

const RunMenu = ({
                   run,
                   accessInfo,
                   publicGroupsByUri,
                   setGroupsState,
                   onRunUpdate,
                   onRefreshRunAndWorkstationData,
                   checklist,
                   postProcessors,
                   printers,
                 }) => {
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showWorkstationModal, setShowWorkstationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addToast } = useToasts();

  const isRunEditAllowed = isAccessAllowed(accessInfo, ACCESS_INFO_ACTION_TYPES.EDIT);
  const navigate = useNavigate();

  const onRunLockUnlock = async (locked) => {
    if (!isRunEditAllowed) {
      showAccessError(locked ? 'Lock Run' : 'Unlock Run');
      return;
    }
    setIsSubmitting(true);
    await api.put(run.uri, {
      prefixUrl: false,
      json: {
        // eslint-disable-next-line camelcase
        pieces_locked: locked,
      },
    });
    const updatedRun = await api.get(run.uri, { prefixUrl: false }).json();
    onRunUpdate(updatedRun);
    setIsSubmitting(false);
  };

  const goToAddPiecePage = () => {
    if (!isRunEditAllowed) {
      showAccessError('Add Pieces');
      return;
    }
    navigate(getRouteURI(routes.runAddPrint,
      { uuid: getUuid(run.uri) }));
  };

  const showAccessError = (actionLabel) => {
    const prohibitedByGroupNames = accessProhibitedByGroupNames(accessInfo, ACCESS_INFO_ACTION_TYPES.EDIT, publicGroupsByUri);
    addToast(
      `Cannot ${actionLabel}. Editing is limited to the Qualified Group "${prohibitedByGroupNames.join(', ')}" and Bureau Managers. Contact your Bureau Manager if you have any questions.`,
      { appearance: 'error' },
    );
  };

  const onWorkstationChange = async ({ workstationType }) => {
    await api.put(run.uri, {
      prefixUrl: false,
      json: postProcessors.length ? {
        // eslint-disable-next-line camelcase
        post_processor: workstationType,
      } : {
        printer: workstationType,
      },
    });
    await onRefreshRunAndWorkstationData();
  };

  if (isSubmitting) {
    // rendering empty menu toggle when submit is in progress
    return (
      <Dropdown alignRight className="float-right">
        <Dropdown.Toggle variant="default" id="dropdown-basic" className="hide-caret">
          <Loader inline showText={false} />
        </Dropdown.Toggle>
      </Dropdown>
    );
  }
  const canChangeWorkstationType = postProcessors?.length > 1 || printers?.length > 1;
  const workstationTypeChoices = postProcessors.length > 1 ?
    postProcessors.map((pp) => ({ value: pp.uri, label: pp.name })) :
    printers.map((p) => ({ value: p.uri, label: p.name }));
  return (
    <>
      <Dropdown alignRight className="float-right">
        <Dropdown.Toggle variant="default" id="dropdown-basic" className="hide-caret">
          <FontAwesomeIcon icon={faEllipsisV} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/* As we moved the comments out of this menu, we now update the run.notes instead of comments  */}
          <Dropdown.Item onClick={() => setShowNotesModal(true)}>Add Notes</Dropdown.Item>
          {
            canChangeWorkstationType &&
            <Dropdown.Item onClick={() => setShowWorkstationModal(true)}>Change Workstation</Dropdown.Item>
          }
          {
            run.pieces_locked
              ? (<Dropdown.Item onClick={() => onRunLockUnlock(false)}>Unlock Run</Dropdown.Item>)
              : (<Dropdown.Item onClick={() => onRunLockUnlock(true)}>Lock Run</Dropdown.Item>)
          }
          <Feature featureName={FEATURES.POC_UK_ORDER_FIELDS}>
            {/* eslint-disable-next-line react/prop-types */}
            <Dropdown.Item disabled={run.pieces_locked} onClick={goToAddPiecePage}>Add Pieces</Dropdown.Item>
          </Feature>

          {
            run.prints?.length > 1 && (
              <>
                <Dropdown.Item onClick={() => setGroupsState(GROUP_STATES.EXPANDED)}>Expand All</Dropdown.Item>
                <Dropdown.Item onClick={() => setGroupsState(GROUP_STATES.COLLAPSED)}>Collapse All</Dropdown.Item>
              </>
            )
          }
          {
            checklist.additional_instruction_url && (
              <Dropdown.Item href={checklist.additional_instruction_url} target="_blank">Additional
                Instructions
              </Dropdown.Item>
            )
          }
        </Dropdown.Menu>
      </Dropdown>
      {showNotesModal && (
        <NotesModal run={run} onClose={() => setShowNotesModal(false)} />
      )}
      {
        canChangeWorkstationType &&
        <WorkstationModal
          isOpen={showWorkstationModal}
          initialWorkstation={run.post_processor || run.printer}
          choices={workstationTypeChoices}
          onSubmit={onWorkstationChange}
          onClose={() => setShowWorkstationModal(false)}
        />
      }
    </>
  );
};

RunMenu.defaultProps = {
  checklist: {},
};

RunMenu.propTypes = {
  run: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    prints: PropTypes.arrayOf(PropTypes.string),
    // eslint-disable-next-line camelcase
    pieces_locked: PropTypes.bool.isRequired,
    // eslint-disable-next-line camelcase
    post_processor: PropTypes.string,
    printer: PropTypes.string,
  }).isRequired,
  accessInfo: PropTypes.shape({
    // eslint-disable-next-line camelcase
    target_uri: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.shape({
      allowed: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
    })),
  }),
  publicGroupsByUri: PropTypes.objectOf(PropTypes.shape({})),
  setGroupsState: PropTypes.func.isRequired,
  onRunUpdate: PropTypes.func.isRequired,
  onRefreshRunAndWorkstationData: PropTypes.func.isRequired,
  checklist: PropTypes.instanceOf(Object),
  postProcessors: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  printers: PropTypes.arrayOf(PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

export default RunMenu;
