/* eslint-disable camelcase */
import TextSprite from '@seregpie/three.text-sprite';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { convertLengthToOtherUnit } from 'src/utils/math';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);

const getConstant = bbSize => {
  const number = Math.max(bbSize.y, bbSize.x);
  const constant = {};
  let coe = Number(number.toExponential().split('e')[0]);
  let exp = 10 ** Number(number.toExponential().split('e')[1]);
  if (coe < 1.5) {
    coe *= 10;
    exp /= 10;
  }
  constant.coe = Math.ceil(coe);
  constant.exp = exp;
  return constant;
};

const MaterialColor = 0x8d5d02;
const MaterialEmissiveColor = 0x8d5d02;
const BackgroundColor = 0xededed;
const FillColor = 0x919191; // grid and material fill
const GridColor = 0x484848;
const BoundingBoxColor = 0x00cc00;

class ModelViewer extends Component {
  static drawBoundingBoxVectors(scene, vectors) {
    try {
      const lineMaterial = new THREE.LineDashedMaterial({
        color: BoundingBoxColor,
        linewidth: 1,
        dashSize: 60,
        gapSize: 10,
      });

      const sideLine = new THREE.Line(new THREE.BufferGeometry(), lineMaterial);
      // TODO change objects according to new three.js version
      if (sideLine.geometry?.vertices) {
        vectors.forEach(vector => {
          sideLine.geometry.vertices.push(vector);
        });
        sideLine.computeLineDistances();
      }
      scene.add(sideLine);
    } catch (error) {
      console.error(error);
    }
  }

  static drawBuildEnvelopeGrid(scene, buildEnvelopeOrigin, buildEnvelope) {
    if (!buildEnvelope) {
      return;
    }

    const gridSizeFactor = 1;
    const gridPositionFactor = 2;
    const gridDivisionsFactor = 20;

    // Fill grid at the bottom of box (0..1..3..2 points)
    const gridDivisions = Math.abs(buildEnvelope.width) / gridDivisionsFactor;
    const gridSize = Math.abs(buildEnvelope.width) / gridSizeFactor;
    const gridHelper = new THREE.GridHelper(gridSize, gridDivisions, GridColor, FillColor).rotateX(
      Math.PI / 2
    );
    gridHelper.position.set(
      buildEnvelope.width / gridPositionFactor + buildEnvelopeOrigin.x,
      buildEnvelope.width / gridPositionFactor + buildEnvelopeOrigin.y,
      0
    );
    scene.add(gridHelper);
  }

  static drawBuildEnvelope(scene, center, size, boxSize, unitScale, mesh, isHawkingUser) {
    /*
     * The printer's build envelope is represented as a rectangular cuboid (8 corners).
     * The coordinates of each corner are stored in the array buildEnvelopeOutlineOrdinates.
     *               DEPTH
     *        (7)-------------(5)
     *        /|              /| H
     *       / |             / | E
     *     (6)-------------(4) | I
     *    H |  |            |  | G
     *    E |  |            |  | H
     *    I |  |            |  | T
     *    G | (3)-----------|-(1)
     *    H | /             | /
     *    T |/              |/ WIDTH
     *     (2)-------------(0)
     *            DEPTH
     */

    // FIXME display 1mx1mx1m printer-independent build envelope.
    //  ch11406 will use related printer-type build_volume.
    // TODO Remove GridHelper when buildEnvelope will be implemented with real printer data
    const buildEnvelope = {
      // 1m = 1000mm
      width: -1000,
      depth: -1000,
      height: 1000,
    };
    const buildEnvelopeOrigin = center
      .clone()
      .sub(new THREE.Vector3(-size / 2, -size / 2, (boxSize.y / 2) * (mesh.scale.y / unitScale)));

    ModelViewer.drawBuildEnvelopeGrid(scene, buildEnvelopeOrigin, buildEnvelope, isHawkingUser);

    const buildEnvelopeOutlineOrdinates = [
      [0, 0, 0],
      [buildEnvelope.width, 0, 0],
      [0, buildEnvelope.depth, 0],
      [buildEnvelope.width, buildEnvelope.depth, 0],
      [0, 0, buildEnvelope.height],
      [buildEnvelope.width, 0, buildEnvelope.height],
      [0, buildEnvelope.depth, buildEnvelope.height],
      [buildEnvelope.width, buildEnvelope.depth, buildEnvelope.height],
    ];
    // create the corners vectors.
    const cornerVectors = [];
    buildEnvelopeOutlineOrdinates.forEach(ordinate => {
      cornerVectors.push(
        buildEnvelopeOrigin.clone().add(new THREE.Vector3(ordinate[0], ordinate[1], ordinate[2]))
      );
    });

    // Base of the build envelope's outline.
    //  The base is separate as it was initially meant to be thicker than the sides.
    // Three.Line's linewidth parameter turned out to work inconsistently across web browsers.
    //  Left separate for future solutions.
    const bottomEnvelopeBoxVectors =
      // Bottom of top of box envelope
      // Order matters (!)
      // (0 → 2) → (2 → 3) → (3 → 1) → (1 → 0)
      [cornerVectors[0], cornerVectors[2], cornerVectors[3], cornerVectors[1], cornerVectors[0]];
    ModelViewer.drawBoundingBoxVectors(scene, bottomEnvelopeBoxVectors);

    // Sides of the build envelope's outline.
    const sideCornersGroups = [
      // Vertical (height) lines:
      [cornerVectors[0], cornerVectors[4]],
      [cornerVectors[1], cornerVectors[5]],
      [cornerVectors[2], cornerVectors[6]],
      [cornerVectors[3], cornerVectors[7]],
      // Top of top of box envelope
      // Order matters, this array must be started and closed with the same point (!)
      // (4 → 5) → (5 → 7) → (7 → 6) → (6 → 4)
      [cornerVectors[4], cornerVectors[5], cornerVectors[7], cornerVectors[6], cornerVectors[4]],
    ];
    sideCornersGroups.forEach(vectorGroup => {
      ModelViewer.drawBoundingBoxVectors(scene, vectorGroup);
    });
  }

  constructor(props) {
    super(props);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
  }

  componentDidMount() {
    const { model, unit, fileUnit, rotation, onRotationChange } = this.props;

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    const scene = new THREE.Scene();

    scene.rotateX(Math.PI / 2);
    scene.rotateY(Math.PI);
    scene.rotateZ(Math.PI / 2);

    const camera = new THREE.PerspectiveCamera(70, width / height, 10, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    const controls = new OrbitControls(camera, renderer.domElement);

    const manager = new THREE.LoadingManager();
    manager.onStart = () => {
      // Called when loading starts.
      this.props.onStart();
    };
    manager.onLoad = () => {
      // Called when the load is complete.
      this.props.onLoadComplete();
    };
    this.camera = camera;

    // add small center of rotation (sphere)
    scene.add(
      new THREE.Mesh(
        new THREE.SphereGeometry(0.4, 8, 8), // sphere radius, width segments, height segments.
        new THREE.MeshBasicMaterial({ color: 0xff0000 })
      )
    );

    // add meatball (axes helper).
    // The render order has been altered to make the meatball burn through (always visible).
    const axesHelper = new THREE.AxesHelper(5);
    axesHelper.renderOrder = 99;
    // Changed to ES6 arrow syntax to fix 'unnamed function' warning.
    axesHelper.onBeforeRender = () => {
      renderer.clearDepth();
    };
    const colors = axesHelper.geometry.attributes.color;
    colors.setXYZ(0, 0, 1, 0); // x-axis : green
    colors.setXYZ(1, 0, 1, 0); // x-axis : green
    colors.setXYZ(2, 1, 0, 0); // y-axis : red
    colors.setXYZ(3, 1, 0, 0); // y-axis : red
    colors.setXYZ(4, 0, 0, 1); // z-axis : blue
    colors.setXYZ(5, 0, 0, 1); // z-axis : blue
    scene.add(axesHelper);

    // object's bounding box.
    const bbox = new THREE.Box3();
    const bbHelper = new THREE.Box3Helper(this.bbox, 0xaaaaaa);
    this.bbox = bbox;
    this.bbHelper = bbHelper;

    const loader = new STLLoader(manager);
    loader.load(model, geometry => {
      const material = new THREE.MeshLambertMaterial({
        color: MaterialColor,
        emissive: MaterialEmissiveColor,
      });

      const mesh = new THREE.Mesh(geometry, material);

      // compute normals to avoid displaying dark (inverted) geometry patches from the provided mesh.
      mesh.geometry.computeVertexNormals();
      mesh.geometry.normalsNeedUpdate = true;

      // mesh.rotation.x = -Math.PI / 2; //--
      mesh.geometry.computeBoundingBox();
      const boundingBox = mesh.geometry.boundingBox.clone();
      // center the object.
      const x = -(boundingBox.max.x + boundingBox.min.x) / 2;
      const y = -(boundingBox.max.y + boundingBox.min.y) / 2;
      const z = -boundingBox.min.z;
      geometry.translate(x, y, z);
      this.mesh = mesh;
      scene.add(mesh);

      const boxSize = boundingBox.clone().getSize(new THREE.Vector3());
      const unitScale = convertLengthToOtherUnit(1, fileUnit, unit);
      boxSize.multiplyScalar(unitScale);
      const { coe, exp } = getConstant(boxSize);

      // grid helper
      const base = 10;
      const halfBase = base / 2;
      const size = (coe + 1) * base;
      const halfSize = size / 2;
      const divisions = size / halfBase;
      const gridHelper = new THREE.GridHelper(size, divisions, GridColor, FillColor);
      gridHelper.rotateX(Math.PI / 2);
      // FIXME Consider to delete another GridHelper since this GridHelper can cover all build plate
      scene.add(gridHelper);
      // sprite and lines (TODO: this comment needs to be more descriptive).
      const spriteText = `${exp} ${unit}`;

      const sprites = [];
      const lines = [];
      const lineGeometries = [];
      const corner = 4;

      const linePositions = [[], [], [], []];
      linePositions[0].push(
        halfSize + 3,
        halfSize - base,
        0,
        halfSize + halfBase,
        halfSize - base,
        0,
        halfSize + halfBase,
        halfSize,
        0,
        halfSize + 3,
        halfSize,
        0
      );
      linePositions[1].push(
        halfSize - base,
        -halfSize - 3,
        0,
        halfSize - base,
        -halfSize - 5,
        0,
        halfSize,
        -halfSize - 5,
        0,
        halfSize,
        -halfSize - 3,
        0
      );
      linePositions[2].push(
        -halfSize - 3,
        -halfSize + base,
        0,
        -halfSize - halfBase,
        -halfSize + base,
        0,
        -halfSize - halfBase,
        -halfSize,
        0,
        -halfSize - 3,
        -halfSize,
        0
      );
      linePositions[3].push(
        -halfSize + base,
        halfSize + 3,
        0,
        -halfSize + base,
        halfSize + halfBase,
        0,
        -halfSize,
        halfSize + halfBase,
        0,
        -halfSize,
        halfSize + 3,
        0
      );

      for (let index = 0; index < corner; index++) {
        sprites[index] = new TextSprite(
          {
            alignment: 'center',
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontSize: size > 50 ? 2 : 1,
            fontStyle: 'normal',
            fontVariant: 'normal',
            fontWeight: 'normal',
            lineGap: 0.25,
            padding: 0.5,
            text: spriteText,
          },
          new THREE.SpriteMaterial({ color: FillColor, fog: true })
        );

        lineGeometries[index] = new THREE.BufferGeometry();
        lineGeometries[index].setAttribute(
          'position',
          new THREE.Float32BufferAttribute(linePositions[index], 3)
        );
        lineGeometries[index].computeBoundingSphere();

        scene.add(sprites[index]);
      }

      sprites[0].position.set(halfSize + 8, halfSize - halfBase, 0);
      sprites[1].position.set(halfSize - halfBase, -halfSize - 8, 0);
      sprites[2].position.set(-halfSize - 8, -halfSize + halfBase, 0);
      sprites[3].position.set(-halfSize + halfBase, halfSize + 8, 0);
      this.sprites = sprites;

      for (let index = 0; index < corner; index++) {
        lines[index] = new THREE.Line(
          lineGeometries[index],
          new THREE.LineBasicMaterial({ color: FillColor })
        );
        scene.add(lines[index]);
      }
      this.lines = lines;

      // change model's scale with unitScale (Minglun's suggested approach).
      const meshScale = (base / exp) * unitScale;
      mesh.scale.set(meshScale, meshScale, meshScale);
      // increasing based on user-vs-file units difference.
      const center = new THREE.Vector3(0, 0, (boxSize.z / 8) * mesh.scale.z);
      this.center = center.multiplyScalar(1 / unitScale);
      controls.target = this.center;
      controls.update();

      const boxHeight = Math.max(boxSize.z / unitScale, size);
      camera.position.set(halfSize * 4, 1.5 * boxHeight, halfSize * 3);
      this.fitCameraToObject(camera, mesh, controls, scene, size);
      // Move the scene down the height of the bounding box, ensures the mesh isn't too high on Z (vertical) axis.
      scene.position.setY(-boxSize.y);

      // set the mesh's orientation to match the retrieved manufacturing rotation.
      mesh.quaternion.setFromEuler(
        new THREE.Euler(
          rotation?.theta_x * (Math.PI / 180) || 0,
          rotation?.theta_y * (Math.PI / 180) || 0,
          rotation?.theta_z * (Math.PI / 180) || 0,
          'ZYX'
        )
      );

      onRotationChange(rotation);

      const { isHawkingUser } = this.props;

      // TODO: The drawing of the BuildEnvelope is currently deactivated for now.
      //       Port this from Geometry to BufferGeometry please.
      ModelViewer.drawBuildEnvelope(scene, center, size, boxSize, unitScale, mesh, isHawkingUser);
    });

    // light
    const ambientLight = new THREE.AmbientLight(0x727270, 1);
    scene.add(ambientLight);
    const lights = [];
    lights[0] = new THREE.DirectionalLight(0xffffff, 2); // Changed to DirectionalLights as point lights not working at the time of implementation.
    lights[1] = new THREE.DirectionalLight(0xffffff, 5);
    lights[2] = new THREE.DirectionalLight(0xffffff, 5);
    lights[0].position.set(0, 200, 0);
    lights[1].position.set(100, 200, 100);
    lights[2].position.set(-100, -200, -100);
    scene.add(lights[0]);
    scene.add(lights[1]);
    scene.add(lights[2]);

    // If the `manufacturing orientation` panel is open or other similar condition (TBD) the (camera) controls
    // need to freeze.
    // This condition might need to be moved elsewhere within this file to fulfill the purpose outlined above here.
    controls.enableRotate = this.props.enableRotate;

    this.scene = scene;
    this.renderer = renderer; // The renderer represents the canvas HTML element.
    this.controls = controls;
    // The `mount` is a thin wrapper div around the canvas, below appends the canvas (renderer) to it.
    this.mount.append(this.renderer.domElement);
    this.start();
  }

  componentDidUpdate(prevProps) {
    if (this.props.mode !== prevProps.mode) {
      this.mesh.material.wireframe = this.props.mode === 'wireframe';
    }
    // update rotation.
    if (JSON.stringify(this.props.rotation) !== JSON.stringify(prevProps.rotation)) {
      this.mesh.quaternion.setFromEuler(
        new THREE.Euler(
          this.props.rotation.theta_x * (Math.PI / 180),
          this.props.rotation.theta_y * (Math.PI / 180),
          this.props.rotation.theta_z * (Math.PI / 180),
          'ZYX'
        )
      );
    }
    this.resizeRenderer();

    // (⚠️ Omitted to prevent the object translating randomly when changing viewport width)
    // update object's bounding box.
    // this.scene.remove(this.bbHelper); // remove previous bounding box.
    // if (this.mesh) {
    //   this.bbox = new THREE.Box3().setFromObject(this.mesh, true); // the bool true is for a more precise bounding box.

    //   // build plate locking.
    //   this.mesh.position.z -= this.bbox.min.z;
    //   this.mesh.position.x -= (this.bbox.min.x + this.bbox.max.x) / 2;
    //   this.mesh.position.y -= (this.bbox.min.y + this.bbox.max.y) / 2;
    // }
    // End of omit.
  }

  componentWillUnmount() {
    this.stop();
    this.renderer.domElement.remove();
  }

  /**
   * Resize the renderer (canvas) on viewport size change.
   */
  resizeRenderer = () => {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  updateAnnotationOpacity() {
    if (!this.mesh) return;
    // Only show the annotation when the annotation is nearest to the camera (within a threshold).
    const minDis = [];
    this.sprites.forEach((s, spriteIndex) => {
      minDis[spriteIndex] = this.camera.position.distanceTo(this.sprites[spriteIndex].position);
      this.sprites[spriteIndex].material.opacity = 0;
      this.lines[spriteIndex].material.visible = false;
    });
    const index = minDis.indexOf(Math.min(...minDis));
    this.sprites[index].material.opacity = 1;
    this.lines[index].material.visible = true;
  }

  animate() {
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
    this.controls.update();
    const lookat = this.center || new THREE.Vector3(0, 0, 1);
    this.camera.lookAt(lookat);
  }

  /**
   * Fits object into the camera view on initial load.
   */
  fitCameraToObject(camera, object, controls, scene, gridSize) {
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(object);
    const size = boundingBox.getSize(new THREE.Vector3());
    const maxSize = Math.max(gridSize, size.z);
    const offset = maxSize > 200 ? 1.1 : 1.8;
    const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
    const fitWidthDistance = fitHeightDistance / camera.aspect;
    const distance = offset * Math.max(fitHeightDistance, fitWidthDistance);
    const direction = controls.target
      .clone()
      .sub(camera.position)
      .normalize()
      .multiplyScalar(distance);
    this.camera.near = distance / 100;
    this.camera.far = distance * 100;
    this.camera.updateProjectionMatrix();
    this.camera.position.copy(controls.target).sub(direction);
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera);
    this.renderer.setClearColor(BackgroundColor, 1);
    this.updateAnnotationOpacity();
  }

  static propTypes = {
    model: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    fileUnit: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    rotation: PropTypes.shape({
      theta_x: PropTypes.number,
      theta_y: PropTypes.number,
      theta_z: PropTypes.number,
    }).isRequired,
    onRotationChange: PropTypes.func.isRequired,
    isHawkingUser: PropTypes.bool.isRequired,
    enableRotate: PropTypes.bool.isRequired,
    canvasHeight: PropTypes.number.isRequired,
    onStart: PropTypes.func.isRequired,
    onLoadComplete: PropTypes.func.isRequired,
  };

  render() {
    const { canvasHeight } = this.props;

    return (
      <div
        ref={mount => {
          this.mount = mount;
        }}
        style={{ width: '100%', height: canvasHeight }}
      />
    );
  }
}

export default ModelViewer;
