import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from 'src/components/header';
import NotFound from 'src/components/not-found';
import useQueryParams from 'src/hooks/useQueryParams';
import { api } from 'src/utils/api';
import { API_RESOURCES, MATERIAL_BATCH_ACTIONS } from 'src/utils/constants';
import { getResourceUri, getRouteURI, getShortUuid, getUuid } from 'src/utils/url';
import userPropType from 'src/utils/user-prop-type';

import routes from '../../../utils/routes';

const CreateInitialBatchPage = ({ user, error }) => {
  const { uuid: materialContainerUuid } = useParams();
  const {
    scannedContainers: _scannedContainers = [],
  } = useQueryParams();

  // Convert the query string separated by comma to array.
  const scannedContainers = _scannedContainers?.split(',') ?? [];
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const navigate = useNavigate();

  const onInitialBatchCreate = async (scannedContainers) => {
    try {
      // API expects list of objects with single URI key
      const scannedContainerUris = scannedContainers.map((uuid) => {
        return { uri: getResourceUri(API_RESOURCES.MATERIAL_CONTAINER, uuid) };
      });

      const payload = {
        metadata: {
          containers: scannedContainerUris,
        },
        /* eslint-disable camelcase */
        source_batch: null,
        action_type: MATERIAL_BATCH_ACTIONS.CREATE_INITIAL_BATCH,
        /* eslint-enable camelcase */
      };

      setSubmitting(true);

      const batchActionResponse = await api.post(`${API_RESOURCES.MATERIAL_BATCH_ACTION}/`, {
        json: payload,
      }).json();
      const batchUri = batchActionResponse['source_batch'];

      setSubmitting(false);

      navigate(getRouteURI(routes.materialContainer,
        {},
        { batch: getUuid(batchUri) }));

    } catch (error_) {
      const { errors } = await error_.response.json();
      setSubmitError(errors[0].title);
      return;
    }

    return new Promise(() => {
    });
  };

  if (error || submitError) {
    // error from run on initialization
    // submitError when print can't be appended to run
    return (
      <>
        <Header title="Add Print" user={user} />
        {
          error ?
            <main role="main" className="text-center">
              <NotFound id={materialContainerUuid} />
            </main>
            :
            <div className="alert alert-danger m-4" role="alert">
              <h1 className="h5">Error</h1>
              <p className="mb-0">{submitError}</p>
            </div>
        }
      </>
    );
  }

  const containerPluralized = scannedContainers.length > 1 ? 'Containers' : 'Container';

  const confirmButton = (
    <div className="optionButtons">
      <button
        type="button"
        className="btn btn-primary btn-action mr-1"
        disabled={isSubmitting}
        onClick={() => onInitialBatchCreate(scannedContainers)}
      >
        Confirm
      </button>
    </div>
  );

  const cancelButton = (
    <div className="optionButtons">
      <Link to={getRouteURI(routes.materialContainerDetails,
        { uuid: materialContainerUuid },
        { scannedContainers })}
      >
        <button type="button" className="btn btn-default btn-action">
          Cancel
        </button>
      </Link>
    </div>
  );

  return (
    <>
      <Header title="Create New Batch" user={user} />
      <main role="main" className="text-center">
        <div>
          <h2 className="header-margin">
            Create new batch with selected amount
          </h2>
          <h3 className="header-margin">
            <span className="badge-circle">{scannedContainers.length}</span>
            &nbsp;{containerPluralized} Total:
          </h3>
          <ol className="batch-container-list">
            {scannedContainers.map((uuid) => (
              <li key={uuid}>
                {getShortUuid(uuid)}
              </li>
            ))}
          </ol>
        </div>
        <div className="row btn-group">
          {confirmButton}
          {cancelButton}
        </div>
      </main>
    </>
  );
};

CreateInitialBatchPage.propTypes = {
  error: PropTypes.shape({}),
  user: userPropType,
};

CreateInitialBatchPage.defaultProps = {
  error: null,
  user: null,
};

export default CreateInitialBatchPage;
