import { MODEL_UNIT_COEFFICIENTS } from './constants';

export function convertLengthToOtherUnit(length, lengthUnit, requestedUnit) {
  if (!MODEL_UNIT_COEFFICIENTS[lengthUnit]) {
    return null;
  }

  if (!MODEL_UNIT_COEFFICIENTS[lengthUnit][requestedUnit]) {
    return null;
  }

  const coef = MODEL_UNIT_COEFFICIENTS[lengthUnit][requestedUnit];

  return length * coef;
}
